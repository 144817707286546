import Cookies from "universal-cookie";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import AAAFileRefresh from "../components/AAAFileRefresh.jsx";
import AAAHitlistInput from "../components/AAAHitlistInput.jsx";
import AAASimpleView from "../components/AAASimpleView.jsx";
import AAASimpleViewbyNDC from "../components/AAASimpleViewByNDC.jsx";
import AAATrimRefresh from "../components/AAATrimRefresh.jsx";
import AAAWeeklyHitlist from "../components/AAAWeeklyHitlist.jsx";
import AAANFIReport from "../components/AAANFIReport.jsx";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { DropdownMenu } from "../components/DropdownMenu.jsx";
import { PulseLoader } from "react-spinners";
import { LoadingModal } from "../components/LoadingModal.jsx";
import { PTPException } from "../utils/models/ptpException.js";
import { changeAaaProcess } from "../redux/aaaProcess.js";
import { changeProgram } from "../redux/program.js";
import { useDispatch } from "react-redux";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";
import resultLogStyles from "../styles/result-log.module.css";
import dropdownMenuStyles from "../styles/dropdown-menu.module.css";

export default function AAA() {
    const cookies = useMemo(() => new Cookies(), []);
    const dispatch = useDispatch();
    const aaaProcessMode = useSelector((state) => state.aaaProcess.processMode);
    const programName = useSelector((state) => state.program.name);
    const downloadFileRef = useRef(null);
    const [downloadFileUrl, setDownloadFileUrl] = useState("");
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [resultFiles, setResultFiles] = useState({});
    const [refreshingResults, setRefreshingResults] = useState(false);

    const listResults = useCallback(
        async (controller) => {
            try {
                setRefreshingResults(() => true);

                const requestParams = {
                    method: "POST",
                    headers: { Authorization: `Bearer ${cookies.get("_auth")}` },
                };
                if (controller) requestParams.signal = controller.signal;

                const filesResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-list-result-files`, requestParams);
                if (!filesResponse.ok) throw new PTPException("Failed to retrieve results");
                const fileResults = await filesResponse.json();

                setResultFiles(() => fileResults?.files || {});
            } catch (err) {
                setResultFiles(() => {});
                if (err.name === "AbortError") return;
                console.log(err);
                alert(err.name === "PTPException" ? err.message : "Failed to retrieve results");
            } finally {
                setRefreshingResults(() => false);
            }
        },
        [cookies]
    );

    const downloadAaaResultFile = async (filename) => {
        try {
            setDownloadLoading(() => true);

            const urlResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-result-files-presigned-url`, {
                method: "POST",
                body: JSON.stringify({
                    filename: filename,
                }),
                headers: { Authorization: `Bearer ${cookies.get("_auth")}` },
            });

            const urlData = await urlResponse.json();
            const downloadUrl = urlData.url;
            console.log(downloadUrl);
            setDownloadFileUrl(downloadUrl);
        } catch (err) {
            console.log(err);
            alert("File could not be downloaded at this time");
        } finally {
            setDownloadLoading(() => false);
        }
    };

    // const downloadAaaResultFileTest = async (filename) => {
    // 	try {
    // 		setDownloadLoading(() => true);

    // 		const urlResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-result-files-presigned-url`, {
    // 			method: "POST",
    // 			body: JSON.stringify({
    // 				filename: filename,
    // 			}),
    // 			headers: { Authorization: `Bearer ${cookies.get("_auth")}` },
    // 		});

    // 		const urlData = await urlResponse.json();
    // 		const downloadUrl = urlData.url;
    // 		// setDownloadFileUrl(downloadUrl);
    // 		const urlResponse2 = await fetch(downloadUrl);
    // 	} catch (err) {
    // 		console.log(err);
    // 		alert("File could not be downloaded at this time");
    // 	} finally {
    // 		setDownloadLoading(() => false);
    // 	}
    // };

    useEffect(() => {
        if (!downloadFileUrl) {
            const controller = new AbortController();
            listResults(controller);
            return () => controller.abort();
        }

        downloadFileRef.current?.click();
    }, [downloadFileUrl, listResults]);

    useEffect(() => {
        dispatch(changeProgram("Select program"));
    }, [dispatch]);

    return (
        <div className="centered-container">
            <div className={dropdownMenuStyles.dropdownMenuContainer}>
                {(() => {
                    let menuOptions = ["Mayne-DERM", "Mayne-WH", "Myfembree", "Optinose", "Ubrelvy", "Vraylar", "Zoryve", "Mounjaro", "Zepbound", "Nurtec"];
                    if (aaaProcessMode === "File Refresh") {
                        menuOptions = ["Mayne", "Myfembree", "Optinose", "Ubrelvy", "Zoryve", "Mounjaro", "Nurtec"];
                        dispatch(changeProgram(programName.split("-")[0]));
                    } else if (aaaProcessMode === "SimpleViewbyNDC") {
                        menuOptions = ["Mayne-DERM", "Mayne-WH"];
                    } else if (aaaProcessMode === "Weekly") {
                        menuOptions = ["Mounjaro", "Zepbound"];
                    } else if (aaaProcessMode === "NFIReport") {
                        menuOptions = ["Ubrelvy", "Vraylar", "Zoryve", "Mounjaro", "Zepbound", "Nurtec"];
                    } else {
                        if (programName === "Mayne") dispatch(changeProgram("Select program"));
                    }

                    return (
                        <DropdownMenu
                            defaultMessage={"Select program"}
                            menuOptions={menuOptions}
                            reduxState={programName}
                            reduxStateChangeFunc={changeProgram}
                        ></DropdownMenu>
                    );
                })()}

                <DropdownMenu
                    defaultMessage={"Select process"}
                    menuOptions={["Daily", "Weekly", "File Refresh", "SimpleView", "SimpleViewbyNDC", "TrimRefresh", "NFIReport"]}
                    reduxState={aaaProcessMode}
                    reduxStateChangeFunc={changeAaaProcess}
                ></DropdownMenu>
            </div>
            {downloadLoading && <LoadingModal text="Downloading file..." />}
            {/* eslint-disable-next-line */}
            <a href={downloadFileUrl} ref={downloadFileRef} />
            <div className="program">
                <h1>Triple A</h1>
                {(() => {
                    if (aaaProcessMode === "Daily") {
                        return <AAAHitlistInput></AAAHitlistInput>;
                    } else if (aaaProcessMode === "Weekly") {
                        return <AAAWeeklyHitlist></AAAWeeklyHitlist>;
                    } else if (aaaProcessMode === "File Refresh") {
                        return <AAAFileRefresh></AAAFileRefresh>;
                    } else if (aaaProcessMode === "SimpleView") {
                        return <AAASimpleView></AAASimpleView>;
                    } else if (aaaProcessMode === "SimpleViewbyNDC") {
                        return <AAASimpleViewbyNDC></AAASimpleViewbyNDC>;
                    } else if (aaaProcessMode === "TrimRefresh") {
                        return <AAATrimRefresh></AAATrimRefresh>;
                    } else if (aaaProcessMode === "NFIReport") {
                        return <AAANFIReport></AAANFIReport>;
                    } else {
                        return <></>;
                    }
                })()}
                <div className={resultLogStyles.resultLogContainer}>
                    <div className={resultLogStyles.resultLogHeader}>
                        {refreshingResults ? (
                            <PulseLoader
                                color="white"
                                size={10}
                                cssOverride={{ color: "white", marginLeft: "auto", marginRight: "0.5rem", "&:hover": { cursor: "pointer" } }}
                            ></PulseLoader>
                        ) : (
                            <RefreshIcon
                                fontSize="large"
                                sx={{ color: "white", marginLeft: "auto", marginRight: "0.5rem", "&:hover": { cursor: "pointer" } }}
                                onClick={listResults}
                            ></RefreshIcon>
                        )}
                    </div>
                    <div className={resultLogStyles.resultLog}>
                        {(() => {
                            let filesToMap = [];

                            if (aaaProcessMode === "Daily" || !aaaProcessMode) {
                                filesToMap = [...(resultFiles?.daily || []), ...(resultFiles?.post || [])];
                            } else if (aaaProcessMode === "File Refresh") filesToMap = resultFiles?.refresh || [];
                            else if (aaaProcessMode === "SimpleView" || aaaProcessMode === "SimpleViewbyNDC") {
                                filesToMap = resultFiles?.simpleview || [];
                            } else if (aaaProcessMode === "TrimRefresh") filesToMap = resultFiles?.trimrefresh || [];
                            else if (aaaProcessMode === "Weekly") filesToMap = resultFiles?.weekly || [];
                            else if (aaaProcessMode === "NFIReport") filesToMap = resultFiles?.nfireport || [];

                            return filesToMap
                                .sort((file1, file2) => {
                                    return (file1?.name || "").localeCompare(file2?.name || "");
                                })
                                .map((file) => (
                                    <div className={resultLogStyles.resultLogEntry} key={file?.name || ""}>
                                        <div className={resultLogStyles.resultLogEntryContainer}>
                                            <div className={resultLogStyles.resultLogEntryTimestamped}>
                                                <p className={resultLogStyles.resultLogTimestamp}>{file.lastModifiedTimestamp}</p>
                                                <p className={resultLogStyles.resultLogText}>{file.name}</p>
                                            </div>
                                            <DownloadIcon
                                                fontSize="large"
                                                sx={{ color: "gray", marginLeft: "auto", marginRight: "0.5rem", "&:hover": { cursor: "pointer" } }}
                                                onClick={() => downloadAaaResultFile(file.name)}
                                            ></DownloadIcon>
                                        </div>
                                    </div>
                                ));
                        })()}
                    </div>
                </div>
            </div>
        </div>
    );
}
